import { Injectable } from '@angular/core';
import { DashboardFilter } from '@models/dashboard-filter';
import { DashboardGroup } from '@models/dashboard-group';
import { DeviceState, GroupTypes } from '@utils/enums/device-enums';

@Injectable({
  providedIn: 'root',
})
export class DashboardStoreService {
  private filter: DashboardFilter | undefined;
  private group: DashboardGroup = {
    type: undefined,
    state: undefined,
  };

  getFilter() {
    return this.filter;
  }

  setFilter(property: string, value: string) {
    this.filter = { property, value };
  }

  clearFilter() {
    this.filter = undefined;
  }

  getGroup() {
    return this.group;
  }

  setGroup(type: GroupTypes | undefined, state: DeviceState | undefined) {
    this.group = { type, state };
  }

  clearGroupType() {
    this.group = { ...this.group, type: undefined };
  }

  clearGroupState() {
    this.group = { ...this.group, state: undefined };
  }
}
